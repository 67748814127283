import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import bg from '../assets/bg2.jpg';

export default createGlobalStyle`
main {
}

header {
  box-shadow: 1px 1px 2px #888;
}

#carregando{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
  }

  .loading-indicator {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;

    img {
      position: relative;
      left: 50%;
      top: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }
  }

  .fullbg {
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .caption {
    font-size: 30pt;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .caption:after {
    content:"\A";
    width:10px;
    height:10px;
    border-radius:50%;
    background: #00bbeb;
    display: inline-block;
    margin: 0 0 -2px;
  }

  .react-multiple-carousel__arrow--right{
    right: 2px!important;
  }

  .react-multiple-carousel__arrow--left{
    left: 2px!important;
  }

  .contato {
    background-color: #DCDCDC;
  }

  .servicos {
    background-color: #7A8A9E!important;
  }

  .card .card-header {
    height: 178px!important;
  }

  .text-description{
    max-height: 60px;
    line-height: 20px;
    height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .whatsapp {
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    box-shadow: 1px 1px 2px #888;
    z-index:1000;
  }
  .whatsapp:hover, .mob-telefone:hover, .mob-whatsapp:hover{
    color:#000;
    text-decoration: none;
  }

  .mob-telefone {
    position:fixed;
    width:100px;
    height:80px;
    bottom:10px;
    left:0px;
    background-color: #1E90FF;
    color:#FFF;
    border-radius: 0px 10px 10px 0px;
    text-align:center;
    font-size:30px;
    box-shadow: 1px 1px 2px #888;
    z-index:1000;
  }

  .mob-whatsapp {
    position:fixed;
    width:110px;
    height:80px;
    bottom:10px;
    right:0px;
    background-color: #25d366;
    color:#FFF;
    border-radius: 10px 0px 0px 10px;
    text-align:center;
    font-size:30px;
    box-shadow: 1px 1px 2px #888;
    z-index:1000;
  }

  .mob-telefone p, .mob-whatsapp p {
    font-size: 12px;
  }

  .react-multi-carousel-dot-list {
    bottom: -6px;
  }
`;
