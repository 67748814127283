import React from 'react';
import { connect } from 'react-redux';
import config from '../../services/config';
import api from '../../services/api';

import { Link } from 'react-router-dom';

function Resultado({dados, edificio}) {

  function onClickEmrpesa(empresa){    
    api.post('/log', {texto: '', id_empresa: empresa.id_empresa, acao: 'CLICK_PESQUISA'});
  }
  
  return (
    <section className="destaques">
      <div className="container">
        <h2 className="d-block text-center mb-4">Resultado da Busca <br /><span style={{fontSize: 20,}}>Empresas encontradas</span></h2>
        <div className="card-deck pb-3 ">
          <div className='col-12'>
            <div className={'row ' + (config.isMobile === 'mobile' ? 'row-cols-1' : (config.isMobile === 'tablet' ? 'row-cols-2' : 'row-cols-3'))}>
            {dados.map((emp, index) => 
              <div key={index} className='col' >
                <div  className="card mb-4 overflow-hidden rounded" style={{paddingLeft: 0, paddingRight: 0}}>
                  <div className="card-header p-0 overflow-hidden">
                  <Link to={{pathname: `${emp.url}`, state: { page: 'home' }}} onClick={onClickEmrpesa(emp)}>
                    <img src={emp.arquivo ? emp.arquivo : "https://dummyimage.com/620x380/cccccc/ffffff.jpg"}  />
                  </Link>
                   </div>
                  <div className="card-body">
                    <h3 className="card-title pricing-card-title"><Link to={emp.url}>{emp.nome}</Link></h3>
                    <div className='text-description'>
                      {emp.descricao.substring(0,100)}...
                    </div>
                    {(emp.categorias) && emp.categorias.split(',').map((tag, index) => 
                      tag.length > 1 && (
                        <span key={index} className="badge badge-secondary py-2 px-3 mt-1 fw-light badge-pill">
                          {tag}
                        </span>
                      )
                    )}
                  </div>
                </div>            
              </div>            
            )}
            </div>
          </div>
        </div> 
      </div>            
    </section>
  );
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Resultado);