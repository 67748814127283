import React from "react";
import GoogleMapReact from 'google-map-react';
import InfoWindow from '../InfoWindow';

const Marker = (props) => {
    const markerStyle = {
      border: '1px solid white',
      borderRadius: '50%',
      height: 10,
      width: 10,
      backgroundColor: 'red',
      cursor: 'pointer',
      zIndex: 10,
    };
  
    return (
      <>
        <div style={markerStyle} />
        <InfoWindow empresa={props.empresa} />
      </>
    );
  };

export default function Map(props){
  const defaultProps = {
    center: {
      lat: -1.3698258836087238,
      lng: -48.44588640283019
    },
    zoom: 16
  };  

  return (
    <div style={{ height: 600, width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCEdTP1g7d0dS_ehXDhAJYZD-uYoI6JHcw'}}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
          empresa={props.emp}
        />
      </GoogleMapReact>
    </div>
  );
}