import React , {useParams} from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";

import Home from './pages/Home';
import Detalhe from './pages/Detalhe';
import Page404 from "./pages/Page404";

const Routes = () => (
  

  <BrowserRouter>
    <Switch>
      <Route exact path="/404" component={Page404} />      
      {/* <Route exact path="/:url" component={() => {
        const [subdomain] = window.location.hostname.split('.');
        const dados = window.location.hostname.split('.');
        console.log(dados, subdomain);
        return Home;      
      }} /> */}
      <Route exact path="/" component={Home} />
      <Route exact path="/:url" component={Detalhe} />
             
    </Switch>
  </BrowserRouter>
);

export default Routes;
