import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from '../../services/config';
import api from '../../services/api';
import axios from 'axios';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useParams } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from 'react-toastify';

import { useAlert } from 'react-alert';
import Map from '../../components/Map';

function Detalhe() {
  const alert = useAlert();
  const location = useLocation();

  const { url } = useParams();
  const [dados, setDados] = useState({
    empresa: '',
    nome: '',
    email: '',
    telefone: '',
    mensagem: '',
    to: ''
  });
  

  const [servicos, setServicos] = useState([]);
  const [destaques, setDestaques] = useState([]);
  const [empresa, setEmpresa] = useState(null);
  const [imagem, setImagem] = useState(null);
  const [imagemSobre, setImagemSobre] = useState([]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  useEffect(() => {
    
    async function load(){
      const empresa_ = await api.get('/empresa_url', { params: { url }});
      const imagens_ = await api.get('/imagem', { params: { id_empresa: empresa_.data.id_empresa }});
      const servicos_ = await api.get('/empresa/servico', {params: { id_empresa: empresa_.data.id_empresa}});
      const destaques_ = await api.get('/empresa/destaque', {params: { id_empresa: empresa_.data.id_empresa}});
      
      if (!location.state){
        api.post('/log', {texto: '', id_empresa: empresa_.data.id_empresa, acao: 'ACESSO_DIRETO'});
      }

      const destaque_ = destaques_.data[Math.floor(Math.random() * destaques_.data.length)];

      setServicos(servicos_.data);
      setDestaques([...destaques, destaque_]);
      setEmpresa(empresa_.data);
      // console.log(empresa_);

      document.title = empresa_.data.nome;

      // setDados({...dados, to: "rlopeslameira@gmail.com"});
      setDados({...dados, to: empresa_.data.email, empresa: empresa_.data.nome});

      const img = imagens_.data.find(img => img.area == '0');
      setImagem(img);

      const imgs = imagens_.data.filter(img => img.area == '1');
      setImagemSobre(imgs);

    }
    load();
  }, []);

  function gravaLog(e, texto, acao){
    // e.preventDefault();
    api.post('/log', {texto, id_empresa: empresa.id_empresa, acao});
  }

  async function enviaEmail (e) {
    e.target.setAttribute('disabled', true);
    e.target.textContent = 'Aguarde...';
    e.preventDefault();
    
    if ((dados.telefone.length > 0) && (dados.nome.length > 0) && (dados.email.length > 0) && (dados.mensagem.length > 0))
    {
      gravaLog(e,'', 'EMAIL_CONTATO');

      const result = await axios.post('https://conexi.com.br/send.php', dados);
      console.log(result.data);
      if (result.data.status){
        toast.success('E-mail de contato enviado com sucesso!');
      }else{
        toast.success('Falha ao tentar enviar o e-mail de contato.\nTente novamente!');
      }
    }else{
      alert.error('Todos os campos são obrigatórios.');
    }
    e.target.removeAttribute('disabled');
    e.target.textContent = 'Enviar';

  }

  if (empresa && empresa.status == '1'){
    return (<Redirect to={"/"} />);
  }else{
    return (
      <div className="detalhe" id="home">
        {empresa && (
          <Header emp={empresa} />
        )}
        {empresa && (
          <>
          <main role="main">
            {imagem && (
              <div style={{background: `linear-gradient(
                rgba(0, 0, 0, 0.7), 
                rgba(0, 0, 0, 0.7)
              ), url(${imagem.arquivo})`, backgroundSize: 'cover', paddingTop: 86}}>
                <section className="jumbotron overlay rounded-0 pb-5" style={{background: 'none', minHeight: 420,}}>
                  <div className="container">
                    <h1 className=""><span className="d-block" style={ config.isMobile ? {fontSize: 40} : {}}>{empresa.titulo_banner}</span></h1>
                    <p className="lead text-muted mb-5">
                      {empresa.descricao_banner}
                    </p>                                         
                  </div>
                </section>            
              </div>
            )}

            <section className="sobre py-4" id="sobre">
              <div className="container">
                  <div className="row">              
                    <div className={"col-sm-12 col-md-6" + ('mobile, tablet'.includes(config.isMobile) ? ' pb-5' : '')}> 
                        <h3 className="col-12 mb-2 pl-0"><span className="d-block text-uppercase ">Sobre</span>{empresa.nome}</h3>                 
                        <p>
                          {empresa.descricao}
                        </p>
                        {empresa.site && (
                          <a onClick={(e) => gravaLog(e, '', 'CLICK_SITE')} href={empresa.site} target="_blank" className="btn btn-primary btn-lg ml-2">Site</a>
                        )}
                        
                        {empresa.instagram && (
                          <a onClick={(e) => gravaLog(e, '', 'CLICK_INSTAGRAM')} href={empresa.instagram} target="_blank" className="btn btn-primary btn-lg ml-2">Instagram</a>
                        )}
                        
                        {empresa.facebook && (
                          <a onClick={(e) => gravaLog(e, '', 'CLICK_FACEBOOK')} href={empresa.facebook} target="_blank" className="btn btn-primary btn-lg ml-2">Facebook</a>
                        )}
                        
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="row">
                        <div className="col-6 pr-2">
                            <img src={imagemSobre[0] ? imagemSobre[0].arquivo : "https://unsplash.it/681/480/?random"} className="img-fluid"/>
                        </div>               
                        <div className="col-6 pl-2">
                          <img src={imagemSobre[1] ? imagemSobre[1].arquivo : "https://unsplash.it/681/480/?random"} className="img-fluid"/>
                        </div>
                        <div className="w-100 my-2"></div>
                        <div className="col-12">
                          <img src={imagemSobre[2] ? imagemSobre[2].arquivo : "https://unsplash.it/681/480/?random"} className="img-fluid"/>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </section>

            <section className="servicos py-5 mb-5 mt-3" id="servicos">
              <div className="container">
                <h2 className="mb-5">Nossos Serviços</h2>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={true}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  keyBoardControl={true}
                  customTransition="transform 1000ms ease-in-out"
                  transitionDuration={1000}>
                    {servicos &&  servicos.map((serv, index) =>
                      <div key={index} className="card rounded-0 border-0 text-center m-3" style={{height: 400, maxHeight: 400,}}>
                        <div className="pb-0"><img src={serv.imagem} className="img-fluid"/></div>
                        <div className="card-body">
                          <h5 className="card-title">{serv.nome}</h5>
                          <p className="card-text">{serv.descricao}</p>                
                        </div>
                      </div>
                    )}
                  </Carousel>
              </div>
            </section>
            
            <section className="destaque">
              <div className="container">
                {destaques.map((dest, index) =>
                <div key={index} className="row">
                  <div className="col-md-6 col-sm-12 pl-3 pr-3">
                    <h3 className="col-12 mb-2 pl-0"><span className="d-block text-uppercase ">Destaques</span>{dest.titulo}</h3>                 
                    <p>
                      {dest.descricao}
                    </p>
                  </div>
                  <div className="col-md-6  col-sm-12">
                    <img src={dest.imagem ? dest.imagem : "https://unsplash.it/683/680/?random"} className="img-fluid"/>
                  </div>
                </div>
                )}
              </div>
            </section>
          
            <section className="contato py-3 mt-5" id="contatos">
              <div className="container">
                <form>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h3 className="mb-2 pl-0 mb-5"><span className="d-block text-uppercase ">Contato</span>Entre em contato para mais informações</h3>
                    </div>
                    <div className="col-md-7">
                      <div className="form-group">
                        <textarea onChange={e => setDados({...dados, mensagem: e.target.value})} className="form-control form-control-lg rounded-0" placeholder="Dúvida" value={dados.mensagem} rows="8"></textarea>
                      </div>
                    </div> 
                    <div className="col-md-5">                
                      <div className="form-group">                    
                        <input type="text" onChange={e => setDados({...dados, telefone: e.target.value})} className="form-control form-control-lg rounded-0" placeholder="Telefone" value={dados.telefone}/>
                      </div>
                      <div className="form-group">                    
                        <input type="text" onChange={e => setDados({...dados, nome: e.target.value})} className="form-control form-control-lg rounded-0" value={dados.nome} placeholder="Digite seu nome"/>
                      </div>
                      <div className="form-group">                    
                        <input type="email" onChange={e => setDados({...dados, email: e.target.value})} className="form-control form-control-lg rounded-0" value={dados.email} placeholder="Qual seu e-mail?"/>
                      </div>
                      <button onClick={enviaEmail} className="btn btn-block btn-primary btn-md p-3 mb-2 text-uppercase">Enviar</button>
                    </div>
                    
                  </div>
                </form>
                  <div className="row py-5 text-center contato-items">
                    <div className="col-md">
                      <span className="item-contato"><i className="flaticon-envelope"></i></span>
                      <h4>E-mail</h4>
                      <p><a onClick={(e) => gravaLog(e, '', 'CLICK_EMAIL')} href={'mailto:' + empresa.email}>{empresa.email}</a></p>
                    </div>
                    <div className="col-md">
                      <span className="item-contato"><i className="flaticon-telephone"></i></span>
                      <h4>Telefone</h4>
                      <p><a onClick={(e) => gravaLog(e, '', 'CLICK_TELEFONE')} href={'tel:' + empresa.telefone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '').trim()}>{empresa.telefone}</a></p>
                    </div>
                    <div className="col-md">
                      <span className="item-contato"><i className="flaticon-pin"></i></span>
                      <h4>Endereço</h4>
                      <p>{empresa.endereco}</p>
                    </div>
                  </div>
              </div>
            </section>   

            <section className="destaque" id="localizacao">
              <Map emp={empresa}/>
            </section>              
            {('mobile, tablet'.includes(config.isMobile) && empresa.telefone) && (
              <a onClick={(e) => gravaLog(e, '', 'CLICK_TELEFONE')} href={'tel:' + empresa.telefone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '').trim()} className={'mob-telefone'} target="_blank">
                <i style={{marginTop: 16, }} className="fa fa-phone"></i>
                <p>Ligar</p>
              </a>
            )}

            {empresa.whatsapp && (
              <a onClick={(e) => gravaLog(e, '', 'CLICK_WHATSAPP')} href={`https://wa.me/55${empresa.whatsapp}?text=Olá! Encontrei vocês através da Plataforma CONEXI e gostaria de uma informação.`} className={'mobile, tablet'.includes(config.isMobile) ? 'mob-whatsapp' : 'whatsapp'} target="_blank">
                <i style={{marginTop: 16, }} className="fa fa-whatsapp"></i>
                { 'mobile, tablet'.includes(config.isMobile) && (
                  <p>Whatsapp</p>
                )}
              </a>       
            )}            
            
          </main>
          <Footer/>
          </>
        )}      
      </div>
    );
  }
}

export default connect((state) => ({
    sistema: state.sistema,
    usuario: state.usuario
}))(Detalhe);