function isMobile() {
  const _width = document.body.clientWidth;
    if (_width < 600)
      return 'mobile';
    else if (_width >= 600 && _width <= 890)
      return 'tablet';
    else
      return 'web';
}

export default {
  versao: '0.2.3',
  nome: 'Edifícil Comercial',
  isMobile: isMobile(),
  urlLogo: 'https://diariooficial.netlify.app/logos/',
  baseURL: "https://api.conexi.com.br/",
  baseFiles: "https://api.conexi.com.br/",
  // baseURL: "http://api.edificiocomercial.com/",
};
