import React from 'react';

import instagram from '../../assets/instagram.png';
import linkedin from '../../assets/linkedin.png';
import facebook from '../../assets/facebook.png';
import logo from '../../assets/conexi_branco.png';

function Footer() {
  return (
    <footer className="pb-4">
      <div className="container">
        <div className="row py-5">
          <div className="col-md">
            <a href='https://conexi.com.br' target="_blank">
              <img src={logo} className="img-fluid" style={{width: 180, padding: 5, marginBottom: 8}}/>
            </a>
            <p style={{fontSize: 12}}>Plataforma responsável por centralizar todos os serviços localizados em edifícios comerciais e disponibilizar ao público interno e externo de forma rápida e fácil.</p>
            <div className="redes-sociais" style={{height: 80,}}>
              <a href="https://instagram.com/plataformaconexi" title="Instagram" target="_blank" className=" mr-2 d-inline-block border rounded-cicled-inline-block border rounded-circle">
                <img src={instagram} width="40"/>
              </a>
              <a href="https://www.facebook.com/plataformaconexi " title="Facebook" target="_blank" className=" mr-2 d-inline-block border rounded-cicled-inline-block border rounded-circle">
                <img src={facebook} width="40"/>
              </a>
              <a href="https://www.linkedin.com/company/plataformaconexi" title="linkedin" target="_blank" className=" mr-2 d-inline-block border rounded-cicled-inline-block border rounded-circle">
                <img src={linkedin} width="40"/>
              </a>
            </div>
          </div>          
          <div className="col-md">
            <h5>Contato</h5>
            <ul className="list-unstyled text-small">
              <li><a className="text-muted" href="mailto:contato@conexi.com.br" target="_blank"><i className="flaticon-envelope"></i> contato@conexi.com.br</a></li>
              <li><a className="text-muted" href="#"><i className="flaticon-telephone"></i> (91) 98253-9091</a></li>
              <li><a className="text-muted" href="#"><i className="flaticon-pin"></i> Ed. Parque Office, 4300</a></li>                        
            </ul>
          </div>        
        </div>
        <div className="row copyright">
          <div className="col">Copyright &copy; 2021 All rights reserved | Desenvolvido por Jeta Tecnologia</div>
          <div className="col-auto">www.conexi.com.br</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;