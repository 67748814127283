import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import carregando from './assets/aguarde.gif';

const LoadingIndicator = props => {
  return (
    
    <div id="loadingIncicator" style={{ display: 'none', backgroundColor: 'rgba(255, 255, 255, 1)'}}>
      <img src={carregando} alt="Imagem"  width="80%" style={{maxWidth: 500}}/>
    </div>
  );
}

ReactDOM.render(
  <>
    <App />
    <LoadingIndicator />
  </>,
  document.getElementById('root')
);

