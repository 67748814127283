import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../services/config';
import AnchorLink from  'react-anchor-link-smooth-scroll';

function Header(props) {
  const { edificio } = useParams();
  const [ed, setEd] = useState(props.emp);

  return (
    <>
      <div>

      <header style={{position: 'fixed', zIndex: 99999, backgroundColor: 'white', width: '100%'}}>
        <div className="container" id="topo">
          <nav className="navbar navbar-expand-lg navbar-light">
            {ed.logo ? (
              <img style={{height: 56}} className="img-fluid" SRC={config.baseFiles + ed.logo} title={ed.nome}/>
            ) : (
              <a className="navbar-brand text-uppercase" href={'/'+ed.url}>{ed.nome}</a>
            )}
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu-principal" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button> 
            <div className="collapse navbar-collapse" id="menu-principal">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <AnchorLink className="nav-link" href="#home">Home</AnchorLink>
                </li>
                <li className="nav-item">
                  <AnchorLink className="nav-link" href="#sobre">Sobre</AnchorLink>
                </li>
                <li className="nav-item">
                  <AnchorLink className="nav-link" href="#servicos">Serviços</AnchorLink>
                </li>
                
                <li className="nav-item">
                  <AnchorLink className="nav-link" href="#contatos" tabIndex="-1">Contato</AnchorLink>
                </li>
              </ul> 
            </div>
            
          </nav>   
        </div>           
      </header>
    

      </div>
    </>
    
  );
}

export default Header;