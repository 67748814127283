import React from 'react';
import config from '../../services/config';

export default function InfoWindow({empresa}) {
  console.log(empresa);

  const infoWindowStyle = {
    position: 'relative',
    bottom: 110,
    width: 240,
    height: 100,
    left: 5,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16, color: '#000' }}>
        {/* <img style={{width: '100%'}} src={empresa.logo ? config.baseFiles + empresa.logo : 'https://parqueshoppingbelem.com.br/lojas_files/25235.jpg'}/> */}
        <b>{empresa.nome}</b><br />
        <span style={{fontSize: 10}}>{empresa.descricao.substring(0,100) + '...'}</span>
      </div>      
    </div>
  );
};
